// @flow
import * as React from 'react'
import Client from 'mz-sdk/client'
import moment from 'moment'
import config from '../../config'
import { FormattedMoment } from 'mz-intl'
import { useDataLoader } from 'mz-ui-kit/logic/DataLoader/useDataLoader'
import useCachedState from '../../utils/useCachedState'
import buildDetailedLocation from 'mz-sdk/services/trips/builders/detailedLocation'
import { useStyletron } from 'baseui'
import { Tag, VARIANT } from 'baseui/tag'
import { StyledLink } from 'baseui/link'
import { ProgressBar } from 'baseui/progress-bar'
import { Block } from 'baseui/block'
import { TableFilter, StringColumn, DateColumn } from '../TableFilter'
import { Notification, KIND as NOTIFICATION_KIND } from 'baseui/notification'
import Overflow from 'baseui/icon/overflow'
import { Button, KIND } from 'baseui/button'
import { Pagination } from 'baseui/pagination'
import { Skeleton } from 'baseui/skeleton'
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from 'baseui/popover'
import { StatefulMenu } from 'baseui/menu'
import {
  CACHED_RESERVATION_PAGE,
  CACHED_RESERVATION_FILTERS,
} from '../../keyvalue'
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from '../Table'
import posthog from 'posthog-js'

type DataType = {
  count: number,
  pages: number,
  results: Array<any>,
}

const FILTERS = {
  firstName: StringColumn({ title: 'First Name' }),
  lastName: StringColumn({ title: 'Last Name' }),
  orderDate: DateColumn({
    title: 'Date Of Booking',
    caption: 'Select a date range',
  }),
  reservationId: StringColumn({ title: 'Reservation ID' }),
  partnerTrackingId: StringColumn({ title: 'Partner Tracking ID' }),
}

const FILTER_COLUMNS = [
  FILTERS.firstName,
  FILTERS.lastName,
  FILTERS.orderDate,
  FILTERS.reservationId,
  FILTERS.partnerTrackingId,
]

const QUERY_BUILDER = new Map([
  [FILTERS.firstName, (data) => ({ first_name: data.query })],
  [FILTERS.lastName, (data) => ({ last_name: data.query })],
  [FILTERS.reservationId, (data) => ({ confirmation_number: data.query })],
  [FILTERS.partnerTrackingId, (data) => ({ partner_tracking_id: data.query })],
  [
    FILTERS.orderDate,
    (date) => {
      const base = { timestamp__from: moment(date.range[0]).format() }
      return date.range.length > 1
        ? { ...base, timestamp__to: moment(date.range[1]).format() }
        : base
    },
  ],
])

function buildFilterQuery(filters) {
  if (!filters) return null

  return Array.from(filters).reduce((acc, [title, filterParams]) => {
    const columnIndex = FILTER_COLUMNS.findIndex((c) => c.title === title)
    const column = FILTER_COLUMNS[columnIndex]
    if (!column) return acc

    const builder = QUERY_BUILDER.get(column)
    if (!builder) return acc

    return { ...acc, ...builder(filterParams) }
  }, {})
}

function createLoadingResults(size) {
  return [...Array(size).keys()].map(() => [
    <Skeleton
      key="1"
      width={`${150 + randomInteger(-30, 30)}px`}
      height="20px"
      animation
    />,
    <Skeleton key="2" width="100px" height="20px" animation />,
    <Skeleton
      key="3"
      width={`${200 + randomInteger(-60, 60)}px`}
      height="20px"
      animation
    />,
    <Skeleton
      key="4"
      width={`${200 + randomInteger(-60, 60)}px`}
      height="20px"
      animation
    />,
    <Skeleton
      key="5"
      width={`${150 + randomInteger(-30, 30)}px`}
      height="20px"
      animation
    />,
    <Skeleton
      key="6"
      width={`${80 + randomInteger(-30, 30)}px`}
      height="20px"
      animation
    />,
    '',
    '',
  ])
}

function randomInteger(min, max) {
  return Math.random() * (max - min) + min
}

function createRealResult(reservation) {
  return [
    `${reservation.first_name} ${reservation.last_name}`,
    <FormattedMoment
      key="1"
      value={moment(reservation.timestamp)}
      format="L"
    />,
    buildDetailedLocation(reservation.start_location || {}).fullAddress,
    buildDetailedLocation(reservation.end_location || {}).fullAddress,
    <div key="1">
      {reservation.confirmation_number}
      <div style={{ marginLeft: '-5px' }}>
        {!!reservation.cancelled && (
          <Tag variant={VARIANT.solid} closeable={false} kind="negative">
            Cancelled
          </Tag>
        )}
        {!!reservation.rebooked_id && (
          <Tag variant={VARIANT.solid} closeable={false} kind="warning">
            Re-booked
          </Tag>
        )}
      </div>
    </div>,
    reservation.amount_paid.display,
    reservation.id,
    reservation.partner_ref,
    reservation.confirmation_number,
  ]
}

export default () => {
  const [css] = useStyletron()
  const [currPage, setCurrPage] = useCachedState(CACHED_RESERVATION_PAGE, 1)
  const [filters, setFilters] = useCachedState(CACHED_RESERVATION_FILTERS, null)
  const [voucherDownloadError, setVoucherDownloadError] = React.useState(false)
  const filterQuery = buildFilterQuery(filters)

  const [{ data, loading, error }] = useDataLoader<DataType>(async () => {
    const result = await Client.get('/partners-stats/reservations/', {
      query: { ...filterQuery, page: currPage },
    })
    const pages = Math.ceil(result.count / 20)
    return {
      pages,
      count: result.count,
      results: result.results.map(createRealResult),
    }
  }, [currPage, filterQuery])

  if (error) {
    return (
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <Notification kind={NOTIFICATION_KIND.negative}>
          We are sorry, but we can&apos;t load your reservations at this time.
          Please try again later or contact our{' '}
          <StyledLink href="mailto:support@mozio.com">support</StyledLink> for
          further assistance
        </Notification>
      </div>
    )
  }

  function handleChangePage({ nextPage }) {
    setCurrPage(nextPage)
  }

  async function handleDownloadVoucher(reservationId, hashedId) {
    setVoucherDownloadError(false)
    try {
      const response = await Client.get(
        `/reservations/${reservationId}/voucher/`
      )
      const linkSource = `data:application/json;base64,${response.voucher_base64_pdf}`
      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = `Voucher_${hashedId}.pdf`
      downloadLink.click()
    } catch {
      setVoucherDownloadError(true)
    }
  }

  const actualResults = data ? data.results : createLoadingResults(20)

  return (
    <div>
      <div className={css({ marginBottom: '20px', marginTop: '20px' })}>
        <TableFilter
          columns={FILTER_COLUMNS}
          value={filters}
          onChange={setFilters}
          placeholder={'Press "Add Filter" on the right to filter the results'}
          isLoading={loading}
          totalCount={data?.count}
          clearOnEscape
        />
      </div>
      {voucherDownloadError && (
        <Notification kind={NOTIFICATION_KIND.negative}>
          {`We couldn't generate the voucher. Please try again later.`}
        </Notification>
      )}
      <StyledTable $isLoading={loading}>
        <StyledHead>
          <StyledHeadCell>Reservation Under</StyledHeadCell>
          <StyledHeadCell $maxWidth="100px">Date Of Booking</StyledHeadCell>
          <StyledHeadCell>
            From
            <br />
            To
          </StyledHeadCell>
          <StyledHeadCell>Reservation ID</StyledHeadCell>
          <StyledHeadCell $maxWidth="120px">Price</StyledHeadCell>
          <StyledHeadCell $maxWidth="30px" />
        </StyledHead>
        <StyledBody>
          {actualResults.map((row, index) => (
            <StyledRow key={index}>
              <StyledCell>{row[0]}</StyledCell>
              <StyledCell $maxWidth="100px">{row[1]}</StyledCell>
              <StyledCell>
                <Block>
                  <div>{row[2]}</div>
                  <div>{row[3]}</div>
                </Block>
              </StyledCell>
              <StyledCell>{row[4]}</StyledCell>
              <StyledCell $maxWidth="120px">{row[5]}</StyledCell>
              <StyledCell $maxWidth="30px">
                <StatefulPopover
                  content={
                    <StatefulMenu
                      items={[
                        {
                          label: 'Confirmation Page',
                          event: 'Confirmation Page Link Clicked',
                          href: `${
                            config.BASE_APP_URL
                          }/confirmation/?id=${row[6].toString()}&ref=${row[7].toString()}`,
                        },
                        {
                          label: 'Cancel',
                          event: 'Cancel Link Clicked',
                          href: `${
                            config.BASE_APP_URL
                          }/cancel-booking/?id=${row[6].toString()}&ref=${row[7].toString()}`,
                        },
                        {
                          label: 'Update',
                          key: 'Update Link Clicked',
                          href: `${
                            config.BASE_APP_URL
                          }/change-booking/?id=${row[6].toString()}&ref=${row[7].toString()}`,
                        },
                        {
                          label: 'Download Voucher',
                          key: 'Download Voucher Clicked',
                        },
                      ]}
                      overrides={{
                        ListItemAnchor: {
                          props: {
                            target: '_blank',
                          },
                        },
                      }}
                      onItemSelect={({ item }) => {
                        posthog.capture(item.event)

                        if (item.key === 'Download Voucher Clicked') {
                          handleDownloadVoucher(row[6], row[8])
                        }
                      }}
                    />
                  }
                  accessibilityType={'tooltip'}
                  triggerType={TRIGGER_TYPE.click}
                  placement={PLACEMENT.bottomLeft}
                >
                  <Button kind={KIND.minimal}>
                    <Overflow />
                  </Button>
                </StatefulPopover>
              </StyledCell>
            </StyledRow>
          ))}
        </StyledBody>
      </StyledTable>
      {!data && <Skeleton width="400px" height="50px" animation />}
      {data && (
        <ProgressBar
          infinite
          key={currPage}
          overrides={{
            Root: {
              style: {
                visibility: loading ? 'visible' : 'hidden',
              },
            },
          }}
        />
      )}
      {data && (
        <Pagination
          numPages={data.pages}
          currentPage={currPage}
          onPageChange={handleChangePage}
        />
      )}
    </div>
  )
}
