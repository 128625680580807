// NOTE: currently this file does nothing cause we don't have analytics set up for this project

import posthog from 'posthog-js'
import initPosthog from './posthog'

export const setOsano = () => {
  if (!window.Osano?.cm) {
    console.error('Osano Consent Manager is not loaded.') // eslint-disable-line no-console
    return
  }

  window.Osano.cm.addEventListener('osano-cm-consent-saved', (consent) => {
    if (consent.ANALYTICS === 'ACCEPT') {
      initPosthog()
      posthog.opt_in_capturing()
    } else {
      posthog.opt_out_capturing()
    }
  })
}
