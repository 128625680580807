import posthog from 'posthog-js'

function initPosthog() {
  const {
    REACT_APP_MOZIO_PUBLIC_POSTHOG_KEY,
    REACT_APP_MOZIO_PUBLIC_POSTHOG_HOST,
    CONFIG_ENV,
    BUILD_DATE,
    VERCEL_GIT_COMMIT_SHA,
  } = process.env

  window.APP_VERSION = `${BUILD_DATE}-${
    VERCEL_GIT_COMMIT_SHA ? VERCEL_GIT_COMMIT_SHA.slice(0, 7) : ''
  }`

  if (
    !REACT_APP_MOZIO_PUBLIC_POSTHOG_KEY ||
    !REACT_APP_MOZIO_PUBLIC_POSTHOG_HOST
  ) {
    // eslint-disable-next-line no-console
    console.error(
      'Posthog integration is not configured properly so it will not be loaded.'
    )
    return
  }

  posthog.init(REACT_APP_MOZIO_PUBLIC_POSTHOG_KEY, {
    api_host: REACT_APP_MOZIO_PUBLIC_POSTHOG_HOST,
    debug: CONFIG_ENV === 'development',
    disable_session_recording: true,
    loaded: () => {
      posthog.register({
        product: 'Partner dashboard',
        app_version: window.APP_VERSION,
      })
    },
  })
}

export default initPosthog
