// @flow
import * as React from 'react'
import { styled, useStyletron } from 'baseui'
import MozioLogo from '../../components/MozioLogo'
import { Alert } from 'baseui/icon'
import { Field, Form, Formik } from 'formik'
import { Card, StyledBody, StyledAction } from 'baseui/card'
import { Button } from 'baseui/button'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { H3 } from 'baseui/typography'
import { Notification, KIND } from 'baseui/notification'
import { useHistory } from 'react-router-dom'
import doLogin from 'mz-sdk/services/auth/login'
import * as Yup from 'yup'
import posthog from 'posthog-js'

const SigninSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
})

const Container = styled<{}>('div', ({ $theme }) => ({
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: $theme.colors.backgroundSecondary,
}))

function Negative() {
  const [css, theme] = useStyletron()
  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.sizing.scale500,
        color: theme.colors.negative400,
      })}
    >
      <Alert size="18px" />
    </div>
  )
}

function FormikInputField({ name, type = 'text', label, placeholder }) {
  return (
    <Field name={name} type={type}>
      {({ field, meta }) => (
        <FormControl label={label} error={(meta.touched && meta.error) || null}>
          <Input
            type={type}
            placeholder={placeholder}
            error={meta.touched && meta.error}
            overrides={meta.touched && meta.error ? { After: Negative } : {}}
            {...field}
          />
        </FormControl>
      )}
    </Field>
  )
}

export default () => {
  const history = useHistory()
  return (
    <Container>
      <div>
        <MozioLogo size="2x" />
        <H3>Partner&apos;s Dashboard</H3>
        <Formik
          validationSchema={SigninSchema}
          initialValues={{ email: '', password: '' }}
          onSubmit={async (values, actions) => {
            try {
              actions.setStatus(null)
              const response = await doLogin(values)

              posthog.register({
                user_id: response.data.id,
                user_email: response.data.email,
                user_ref_partner_name: response.data.ref_partner_name,
              })

              history.push('/dashboard')
            } catch (e) {
              actions.setStatus(
                e.message ||
                  'Something went wrong, please contact us to find the problem info@mozio.com'
              )
            }
            actions.setSubmitting(false)
          }}
        >
          {({ isSubmitting, status }) => (
            <Form>
              <Card overrides={{ Root: { style: { width: '400px' } } }}>
                <StyledBody>
                  <FormikInputField
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="type your email"
                  />
                  <FormikInputField
                    name="password"
                    type="password"
                    label="Password"
                    placeholder="type your password"
                  />
                  {!!status && (
                    <Notification kind={KIND.negative}>{status}</Notification>
                  )}
                </StyledBody>
                <StyledAction>
                  <Button type="submit" isLoading={isSubmitting}>
                    Login
                  </Button>
                </StyledAction>
              </Card>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  )
}
